.container {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}

.contact_us {
    padding: 100px 20px;
    background-color: #f8f8f8;
    display: flex;
    justify-content: space-between;
}

.contact_us .right, .contact_us .left {
    width: 45%;
    font-size: 20px;
}

.contact_us .left .block {
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.contact_us .left .icon {
    width: 32px;
    height: 32px;
}

.contact_us .left .mail {
    font-weight: 700;
}

.contact_us .left .address {
    font-weight: 700;
    width: 90%;
}
.contact_us .left .phone {
    font-weight: 700;
}

.contact_us h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
}

.contact_form_container {
    max-width: 600px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.1);
}

.contact_form {
    display: flex;
    flex-direction: column;
}

.form_group {
    margin-bottom: 15px;
}

.form_group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: #333;
}

.form_group input,
.form_group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    color: #333;
}

.form_group input:focus,
.form_group textarea:focus {
    border-color: #00aaff;
    outline: none;
}

.submit_button {
    background-color: #00aaff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.submit_button:hover {
    background-color: #0077cc;
}

@media (max-width: 769px) {
    .container {
        width: 100%;
    }

    .contact_us {
    flex-direction: column;
    }
    .contact_us .right, .contact_us .left {
    width: 100%;
    margin: 40px 0;
    }
    .contact_us .left {
        font-size: small;
    }
    .contact_us h2 {
    font-size: 1.75rem;
    }
}
