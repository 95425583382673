.container {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}

.why_choose_us {
    padding: 50px 20px;
    background-color: #fff;
    text-align: center;
}

.why_choose_us h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.why_choose_us_cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.why_choose_us_card {
    background-color: #f8f8f8;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
}

.why_choose_us_card h3 {
    font-size: 1.3rem;
    color: #333;
}

.why_choose_us_icon {
    font-size: 5rem;
    color: #888;
}

.why_choose_us_card p {
    font-size: 0.9rem;
    color: #666;
    margin: 10px 0;
}

@media (max-width: 768px) {
    .container {
        width: 100%;
    }
}