.container {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}

.who_we_are {
    padding: 100px 20px;
    background-color: #f8f8f8;
    display: flex;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
  
.who_we_are_content {
    max-width: 45%;
}
  
.who_we_are h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

.who_we_are p {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 20px;
}
.who_we_are bold {
    font-weight: 700;
}

.who_we_are_images {
    display: flex;
    gap: 20px;
}

.who_we_are_image {
    max-width: 100%;
    border-radius: 10px;
}

.get_started_button {
    background-color: #00aaff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.get_started_button:hover {
    background-color: #0077cc;
}

@media (max-width: 768px) {
    .container {
        width: 100%;
    }

    .who_we_are {
    flex-direction: column;
    text-align: center;
    }

    .who_we_are_content {
    max-width: 100%;
    }

    .who_we_are_images {
    flex-direction: column;
    }

    .who_we_are_image {
    max-width: 100%;
    margin-bottom: 20px;
    }
}