.container {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}

.footer {
    background-color: #333;
    color: #fff;
    padding: 50px 20px;
    text-align: center;
}

.footer_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.footer_section {
    max-width: 300px;
}

.footer_section h3 {
    margin-bottom: 15px;
    font-size: 1.4rem;
}

.footer_section p {
    font-size: 1rem;
    margin-bottom: 10px;
}

.social_icons {
    display: flex;
    gap: 10px;
}

.social_icons a * {
    color: #fff;
    font-size: 25px;
    transition: color 0.3s ease;
}

.social_icons a:hover {
    color: #00aaff;
}

.footer_bottom {
    margin-top: 50px;
    font-size: 0.9rem;
}

@media (min-width: 769px) {
    .footer_content {
        flex-direction: row;
        justify-content: space-between;
        align-items: start;
    }

    .footer_section {
        text-align: left;
    }
    .footer_section h3 {
        text-align: lef;
    }

    .footer_bottom {
        text-align: center;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .container {
      width: 100%;
      padding: 0 1rem;
    }
}