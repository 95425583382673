.container {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}

.header {
    padding: 5px 20px;
    background-color: #fff;
    box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.1);
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 1000;
}

.header .header_wraper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    font-size: 1.5rem;
    font-weight: 600;
    color: #00aaff;
}

.nav_links {
    display: flex;
    align-items: center;
    gap: 20px;
}

.nav_link {
    text-decoration: none;
    color: #333;
    font-size: 1rem;
    transition: color 0.3s ease;
}

.nav_link:hover {
    color: #00aaff;
}

.free_quote_button {
    background-color: #ff6347;
    color: #fff;
    padding: 10px 20px;
    margin: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.free_quote_button:hover {
    background-color: #ff4500;
}

.mobile_menu_icon {
    display: none;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .container {
        width: 100%;
    }

    .nav_links {
        display: none;
    }

    .nav_links_mobile {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 37px;
        right: 0;
        background-color: #fff;
        width: 100%;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding-block: 30px;
    }

    .nav_link {
        padding: 10px 0;
    }

    .mobile_menu_icon {
        display: block;
        color: #333;
    }
}