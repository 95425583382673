.container {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}

.testimonials {
    padding-top: 40px;
    padding-bottom: 40px;
}
.testimonials .main_title{
    text-align: center;
    color: #333;
    font-size: 2rem;
    margin-block: 40px;
}
.testimonials .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 40px;
}
.testimonials .box {
    background-color: white;
    box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
    border-radius: 6px;
    padding: 20px;
    position: relative;
}
.testimonials .box .image {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    border: 10px solid var(--section-background);
    top: -30px;
    right: 0px;
}
.testimonials .box .image img {
    max-width: 100%;
}
.testimonials .box h3 {
    margin: 0 0 10px;
    color: var(--main-color);
}
.testimonials .box .title {
    color: #777;
    margin-bottom: 10px;
    display: block;
}
.testimonials .box .rate .filled {
    color: #ffc107;
}
.testimonials .box p {
    color: #777;
    line-height: 1.5;
    margin: 10px 0 0;
}

@media (max-width: 768px) {
    .container {
        width: 100%;
    }
}